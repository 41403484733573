import React from 'react';
import { ChevronDown } from 'lucide-react';
import { MonitorInput } from './types';

interface RegionSelectProps {
    newItem: MonitorInput;
    setNewItem: (item: MonitorInput) => void;
    availableRegions: string[];
}

const RegionSelect: React.FC<RegionSelectProps> = ({ newItem, setNewItem, availableRegions }) => (
    <div className="relative">
        <select
            value={newItem.region}
            onChange={(e) => setNewItem({ ...newItem, region: e.target.value })}
            className="w-full bg-gray-700 text-white px-3 py-2 rounded appearance-none pr-8"
        >
            <option value="" disabled>Select Region</option>
            <option key="all" value="all">All</option>
            {availableRegions.map((region) => (
                <option key={region} value={region}>{region}</option>
            ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
            <ChevronDown size={18} />
        </div>
    </div>
);

export default RegionSelect;