import React, { useState, useEffect } from 'react';
import { ChevronDown, Plus, X } from 'lucide-react';
import { Monitor } from './types';
import axios from 'axios';
import { API_URL } from '../../../../constants';



interface MonitorDetailsSectionProps {
    currentMonitor: Monitor;
    setCurrentMonitor: (monitor: Monitor) => void;
    showToast: (message: string, type: "info" | "success" | "error") => void;
}

const MonitorDetailsSection: React.FC<MonitorDetailsSectionProps> = ({
    currentMonitor,
    setCurrentMonitor,
    showToast
}) => {
    const [monitorDelay, setMonitorDelay] = useState<number>(currentMonitor.monitorSettings?.delay || 10);
    const [selectedProxyList, setSelectedProxyList] = useState(currentMonitor.monitorSettings?.selectedProxyList);
    const [selectedKeywordList, setSelectedKeywordList] = useState(currentMonitor.monitorSettings?.selectedKeywordList);
    const [inputsAllowed, setInputsAllowed] = useState(currentMonitor.data.input_allowed);
    const [newExampleInput, setNewExampleInput] = useState('');
    const [exampleInputs, setExampleInputs] = useState<string[]>(currentMonitor.data.example_inputs || []);
    const [editingExampleInputIndex, setEditingExampleInputIndex] = useState<number | null>(null);

    const handleDelayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const numericValue = parseInt(value, 10);
        setMonitorDelay(numericValue);
    };

    const handleProxyListChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = parseInt(event.target.value);
        const selected = currentMonitor.monitorSettings?.proxyLists[selectedId];
        if (selected) {
            setSelectedProxyList({ id: selectedId, name: selected.name });
        }
    }

    const handleKeywordListChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = parseInt(event.target.value);
        const selected = currentMonitor.monitorSettings?.keywordLists[selectedId];
        if (selected) {
            setSelectedKeywordList({ id: selectedId, name: selected.name });
        }
    }

    const handleInputsAllowedChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = event.target.value === "true";
        setInputsAllowed(newValue);
    }

    const handleAddExampleInput = () => {
        if (newExampleInput.trim()) {
            setExampleInputs(prevInputs => [...prevInputs, newExampleInput.trim()]);
            setNewExampleInput('');
        }
    };

    const handleRemoveExampleInput = (index: number) => {
        setExampleInputs(prevInputs => prevInputs.filter((_, i) => i !== index));
    };

    // update example inputs
    useEffect(() => {
        const updateExampleInputs = async () => {
            try {
                const response = await axios.put(
                    `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/example-inputs`,
                    { example_inputs: exampleInputs },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": localStorage.getItem("token") || "",
                        }
                    }
                );

                if (response.status === 200) {
                    // Create a new Monitor object with updated example_inputs
                    const updatedMonitor: Monitor = {
                        ...currentMonitor,
                        data: {
                            ...currentMonitor.data,
                            example_inputs: exampleInputs
                        }
                    };
                    setCurrentMonitor(updatedMonitor);
                    showToast("Example inputs updated successfully", "success");
                }
            } catch (error) {
                console.error('Failed to update example inputs', error);
                showToast("Failed to update example inputs", "error");
            }
        };

        // Only update if exampleInputs have changed from the initial state
        if (JSON.stringify(exampleInputs) !== JSON.stringify(currentMonitor.data.example_inputs)) {
            updateExampleInputs();
        }
    }, [exampleInputs, currentMonitor, setCurrentMonitor]);

    // update delay
    useEffect(() => {
        const updateDelay = async (delay: number) => {
            showToast("Updating delay...", "info")
            try {
                const response = await axios.put(`${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/update`, {
                    delay: delay,
                    type: "delay"
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || "",
                    }
                });

                if (response.status === 200) {
                    console.log('Delay updated successfully');

                    showToast("Successfully updated delay", "success")
                    if (currentMonitor.monitorSettings?.selectedProxyList) {
                        const updatedMonitor: Monitor = {
                            ...currentMonitor,
                            monitorSettings: {
                                ...currentMonitor.monitorSettings,
                                delay: delay
                            }
                        };
                        setCurrentMonitor(updatedMonitor);
                    }
                }
            } catch (error) {
                console.error('Failed to update delay', error);
                showToast("Failed to update delay", "error")
            }
        };

        if (currentMonitor.monitorSettings && currentMonitor.monitorSettings.delay && monitorDelay !== currentMonitor.monitorSettings?.delay) {
            console.log("monitor delay changed:", monitorDelay);
            updateDelay(monitorDelay);
        }
    }, [monitorDelay, currentMonitor.monitorSettings, currentMonitor.data.monitor_uuid]);

    // update proxylist
    useEffect(() => {
        const updateProxyList = async () => {
            try {
                console.log(selectedProxyList)
                if (selectedProxyList) {
                    showToast("Updating proxy list...", "info")
                    const response = await axios.put(`${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/update`, {
                        proxyListId: selectedProxyList.id,
                        type: "proxyList"
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": localStorage.getItem("token") || "",
                        }
                    });

                    if (response.status === 200) {
                        console.log('ProxyList updated successfully');

                        //update here the currentMonitor with setCurrentMonitor
                        if (currentMonitor.monitorSettings) {


                            const updatedMonitor: Monitor = {
                                ...currentMonitor,
                                monitorSettings: {
                                    ...currentMonitor.monitorSettings,
                                    selectedProxyList: selectedProxyList
                                }
                            };

                            // Update the currentMonitor state
                            setCurrentMonitor(updatedMonitor);

                            showToast("Proxylist list updated successfully", "success")
                        }
                        else {
                            showToast("Failed to updated proxy list", "error")

                        }

                    }
                }
            }
            catch (error) {
                console.error('Failed to update proxyList', error);
                showToast("Failed to updated proxy list", "error")

            }
        }
        if (selectedProxyList?.id !== currentMonitor.monitorSettings?.selectedProxyList.id) {
            console.log("ProxyList updated:", selectedProxyList);
            updateProxyList()
        }

    }, [selectedProxyList, currentMonitor.monitorSettings?.selectedProxyList, currentMonitor.data.monitor_uuid]);

    // update keywords (new monitor)
    useEffect(() => {
        const updateKeywordList = async () => {
            try {
                console.log(selectedKeywordList)
                if (selectedKeywordList) {
                    showToast("Updating keywords...", "info")
                    const response = await axios.put(`${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/update`, {
                        keywordID: selectedKeywordList.id,
                        type: "keywords"
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": localStorage.getItem("token") || "",
                        }
                    });

                    if (response.status === 200) {
                        const updatedMonitor: Monitor = {
                            ...currentMonitor,
                            monitorSettings: currentMonitor.monitorSettings
                                ? {
                                    ...currentMonitor.monitorSettings,
                                    selectedKeywordList: selectedKeywordList
                                        ? {
                                            id: selectedKeywordList.id,
                                            name: selectedKeywordList.name
                                        }
                                        : undefined
                                }
                                : undefined
                        };

                        setCurrentMonitor(updatedMonitor);
                        showToast("Keywords updated successfully", "success")
                    }
                }
            }
            catch (error) {
                console.error('Failed to update keywords', error);
                showToast("Failed to updated keywords", "error")

            }
        }

        if (currentMonitor.monitorSettings?.selectedKeywordList && selectedKeywordList?.id !== currentMonitor.monitorSettings?.selectedKeywordList.id) {
            console.log("Keywords updated:", selectedProxyList);
            updateKeywordList()
        }

    }, [currentMonitor.data.monitor_uuid, currentMonitor.monitorSettings?.selectedProxyList.id, selectedKeywordList, selectedProxyList]);





    return (
        <div className="bg-gray-800 p-4 sm:p-6 rounded-lg mb-6 dark-container">

            <h3 className="text-lg font-semibold mb-3">Monitor Details</h3>
            <div className="mb-4" key="delay">
                <label className="block text-sm font-medium mb-1">Delay</label>
                <input
                    type="text"
                    value={monitorDelay}
                    onChange={handleDelayChange}
                    className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                />
            </div>
            <div className="mb-4" key="inputAllowed">
                <label className="block text-sm font-medium mb-1">Inputs Allowed</label>
                <div className="relative">
                    <select
                        value={inputsAllowed.toString()}
                        onChange={handleInputsAllowedChange}
                        className="w-full bg-gray-700 text-white px-3 py-2 rounded appearance-none pr-8"
                    >
                        <option key="true" value="true">True</option>
                        <option key="false" value="false">False</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                        <ChevronDown size={18} />
                    </div>
                </div>
            </div>
            <div className="mb-4" key="proxyList">
                <label className="block text-sm font-medium mb-1">Proxy List</label>
                <div className="relative">
                    <select
                        value={selectedProxyList?.id.toString()}
                        onChange={handleProxyListChange}
                        className="w-full bg-gray-700 text-white px-3 py-2 rounded appearance-none pr-8"
                    >
                        {Object.entries(currentMonitor.monitorSettings?.proxyLists || {}).map(([id, list]) => (
                            <option key={id} value={id}>{list.name}</option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                        <ChevronDown size={18} />
                    </div>
                </div>
            </div>
            {currentMonitor.data.monitor_type === "new" && (
                <div className="mb-4" key="keywords">
                    <label className="block text-sm font-medium mb-1">Keywords</label>
                    <div className="relative">
                        <select
                            value={selectedKeywordList?.id.toString()}
                            onChange={handleKeywordListChange}
                            className="w-full bg-gray-700 text-white px-3 py-2 rounded appearance-none pr-8"
                        >
                            {Object.entries(currentMonitor.monitorSettings?.keywordLists || {}).map(([id, list]) => (
                                <option key={id} value={id}>{list.name}</option>
                            ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                            <ChevronDown size={18} />
                        </div>
                    </div>
                </div>
            )}
            <>
                <label className="block text-sm font-medium mb-1">Example inputs</label>
                <div className="mb-4">
                    <div className="mb-4">
                        <div className="flex">
                            <input
                                type="text"
                                value={newExampleInput}
                                onChange={(e) => setNewExampleInput(e.target.value)}
                                placeholder="Enter new example input"
                                className="flex-grow bg-gray-700 text-white px-4 py-2 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                            <button
                                onClick={handleAddExampleInput}
                                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-r-lg transition duration-150 ease-in-out flex items-center"
                            >
                                <Plus size={18} className="mr-1" />
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                <ul className="space-y-2">
                    {exampleInputs.map((input, index) => (
                        <li key={index} className="flex justify-between items-center bg-gray-700 p-2 rounded">
                            {editingExampleInputIndex === index ? (
                                <input
                                    type="text"
                                    value={input}
                                    onChange={(e) => {
                                        const newInputs = [...exampleInputs];
                                        newInputs[index] = e.target.value;
                                        setExampleInputs(newInputs);
                                    }}

                                    className="flex-grow bg-gray-600 text-white px-2 py-1 rounded mr-2"
                                    autoFocus
                                />
                            ) : (
                                <span
                                    onClick={() => setEditingExampleInputIndex(index)}
                                    className="flex-grow cursor-pointer"
                                >
                                    {input}
                                </span>
                            )}
                            <button
                                onClick={() => handleRemoveExampleInput(index)}
                                className="text-red-500 hover:text-red-600 focus:outline-none"
                            >
                                <X size={18} />
                            </button>
                        </li>
                    ))}
                </ul>
            </>
        </div>
    );
};

export default MonitorDetailsSection;
