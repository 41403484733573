import React, { useRef, useState } from 'react';
import { Search, ExternalLink, Ruler, WebhookIcon, StickyNote, DollarSign, Check, X, Edit2, Trash2, SearchX } from 'lucide-react';
import { MonitorInput } from './types';

interface InputListProps {
    items: MonitorInput[];
    availableRegions: string[];
    toggleActive: (index: number) => void;
    openEditDialog: (item: MonitorInput) => void;
    removeItem: (index: number) => void;
    searchTerm: string;
    setSearchTerm: (term: string) => void;
}

const InputList: React.FC<InputListProps> = ({
    items,
    availableRegions,
    toggleActive,
    openEditDialog,
    removeItem,
    searchTerm,
    setSearchTerm
}) => {
    const [tooltip, setTooltip] = useState<{ content: string; x: number; y: number } | null>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const renderLinkOrText = (link: string) => {
        if (link.toLowerCase().includes('http')) {
            return (
                <>
                    <ExternalLink size={14} className="mr-2 text-blue-400 flex-shrink-0" />
                    <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:underline"
                    >
                        {link}
                    </a>
                </>
            );
        } else {
            return (
                <span className="text-white">{link}</span>
            );
        }
    };
    const handleTooltip = (e: React.MouseEvent<Element>, content: string) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const buttonCenter = rect.left + rect.width / 2;
        setTooltip({
            content,
            x: buttonCenter + window.scrollX,
            y: rect.top + window.scrollY - 5
        });
    };

    const handleTooltipLeave = () => {
        setTooltip(null);
    };


    return (
        <div className="overflow-x-auto">
            <div className="relative w-full sm:w-64">
                <input
                    type="text"
                    placeholder="Search Input..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="bg-gray-700 text-gray-300 rounded-lg pl-10 pr-4 my-2 ml-1 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Search size={18} className="text-gray-500" />
                </div>
            </div>
            <table className="w-full text-sm">
                <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                    <tr>
                        <th className="px-2 sm:px-4 py-2 text-left">Link/Product ID</th>
                        {availableRegions.length > 1 && (
                            <th className="table-hide px-2 sm:px-4 py-2 text-center">Region</th>
                        )}
                        <th className="px-2 sm:px-4 py-2 text-center">Status</th>
                        <th className="px-2 sm:px-4 py-2 text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={item.id || index} className="border-b border-gray-700">
                            <td className="px-2 sm:px-4 py-2 font-medium monitor-link align-middle">
                                <div className="flex items-center">
                                    {renderLinkOrText(item.link)}
                                    {item.sizeRange !== '36-50' && (
                                        <Ruler
                                            size={14}
                                            className="ml-2 text-yellow-400 flex-shrink-0 cursor-pointer"
                                            onMouseEnter={(e) => handleTooltip(e, `Size Range: ${item.sizeRange}`)}
                                            onMouseLeave={handleTooltipLeave}
                                            onClick={(e) => handleTooltip(e, `Size Range: ${item.sizeRange}`)}
                                        />
                                    )}
                                    {item.specialWebhook && (
                                        <WebhookIcon
                                            size={14}
                                            className="ml-2 text-purple-400 flex-shrink-0 cursor-pointer"
                                            onMouseEnter={(e) => handleTooltip(e, `Special Webhook`)}
                                            onMouseLeave={handleTooltipLeave}
                                            onClick={(e) => handleTooltip(e, `Special Webhook`)}
                                        />
                                    )}
                                    {item.note && (
                                        <StickyNote
                                            size={14}
                                            className="ml-2 text-green-400 flex-shrink-0 cursor-pointer"
                                            onMouseEnter={(e) => handleTooltip(e, `Note: ${item.note}`)}
                                            onMouseLeave={handleTooltipLeave}
                                            onClick={(e) => handleTooltip(e, `Note: ${item.note}`)}
                                        />
                                    )}
                                    {item.priceLimit !== undefined && item.priceLimit !== null && (
                                        <DollarSign
                                            size={14}
                                            className="ml-2 text-orange-400 flex-shrink-0 cursor-pointer"
                                            onMouseEnter={(e) => handleTooltip(e, `Price Limit: ${item.priceLimit}`)}
                                            onMouseLeave={handleTooltipLeave}
                                            onClick={(e) => handleTooltip(e, `Price Limit: ${item.priceLimit}`)}
                                        />
                                    )}
                                </div>
                            </td>
                            {availableRegions.length > 1 && (
                                <td className="table-hide px-2 sm:px-4 py-2 text-center align-middle">
                                    {item.region || availableRegions[0]}
                                </td>
                            )}
                            <td className="px-2 sm:px-4 py-2 text-center align-middle">
                                <span className={`px-2 py-1 rounded text-xs font-medium ${item.active ? 'bg-green-800 text-green-300' : 'bg-red-800 text-red-300'}`}>
                                    {item.active ? 'Active' : 'Inactive'}
                                </span>
                            </td>
                            <td className="px-2 py-2 align-middle w-24">
                                <div className="grid grid-cols-3 gap-1 justify-items-center">
                                    <button
                                        onClick={() => toggleActive(index)}
                                        className={`p-1 rounded ${item.active ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700'}`}
                                        title={item.active ? 'Deactivate' : 'Activate'}
                                    >
                                        {item.active ? <X size={12} /> : <Check size={12} />}
                                    </button>
                                    <button
                                        onClick={() => openEditDialog(item)}
                                        className="p-1 rounded bg-yellow-600 hover:bg-yellow-700"
                                        title="Edit"
                                    >
                                        <Edit2 size={12} />
                                    </button>
                                    <button
                                        onClick={() => removeItem(index)}
                                        className="p-1 rounded bg-red-600 hover:bg-red-700"
                                        title="Delete"
                                    >
                                        <Trash2 size={12} />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {items.length === 0 && (
                <div className="w-full flex justify-center">
                    <div className="flex flex-col items-center justify-center py-12 px-4 text-center rounded-lg shadow-inner max-w-md">
                        <SearchX size={48} className="text-gray-500 mb-4" />
                        <h3 className="text-xl font-semibold text-gray-300 mb-2">No inputs found</h3>
                        <p className="text-gray-400">
                            There are no matching inputs. Try adjusting your search or add a new input.
                        </p>
                    </div>
                </div>
            )}
            {tooltip && (
                <div
                    ref={tooltipRef}
                    className="fixed bg-gray-800 text-white px-3 py-2 rounded-lg shadow-lg z-50 text-sm transition-opacity duration-150 ease-in-out"
                    style={{
                        left: `${tooltip.x}px`,
                        top: `${tooltip.y}px`,
                        transform: 'translate(-50%, -100%)',
                        opacity: tooltip ? 1 : 0,
                        pointerEvents: 'none',
                        backgroundColor: "#2a2d3e"
                    }}
                >
                    {tooltip.content}
                    <div
                        className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-[#2a2d3e]"
                    ></div>
                </div>
            )}
        </div>
    );
};

export default InputList;
