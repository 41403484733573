import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import MonitorTable from "./MonitorTable";
// import MonitorSettings from "./MonitorSettings";

import ProxySettings from "./ProxySettings";
import KeywordSettings from "./KeywordSettings";
import { API_URL } from "../../../constants";
import MonitorSettings from "./MonitorSettings/MonitorSettings";


interface MonitorProps {
    handleLogOut: () => void;
    handleBlur: () => void;
    handleLoading: (loading: boolean) => void;
    developer: boolean;

}
interface Webhook {
    region: string;
    webhook_url: string;
}

interface MonitorData {
    monitor_name: string;
    monitor_uuid: string;
    input_allowed: boolean;
    monitor_type: string;
    product: string;
    product_name: string;
    example_inputs: string[]
}
// ProxyList
interface ProxyList {
    id: number;
    name: string;
}

interface ProxyListDict {
    [key: number]: ProxyList;
}

// Keywords
interface KeywordList {
    id: number;
    name: string;
}

interface KeywordListDict {
    [key: number]: KeywordList;
}
interface MonitorSettingsInterface {
    delay: number;
    proxyLists: ProxyListDict;
    keywordLists: KeywordListDict;
    selectedProxyList: ProxyList;
    selectedKeywordList?: KeywordList;
}

interface Monitor {
    data: MonitorData;
    webhooks: Webhook[];
    monitorSettings?: MonitorSettingsInterface;
    inputs: MonitorInput[];
}

interface MonitorInput {
    id: number;
    link: string;
    active: boolean;
    sizeRange: string;
    specialWebhook?: string;
    region: string;
}
interface MonitorSettingsProps {
    currentMonitor: Monitor;
    handleBackToTable: () => void;
    setCurrentMonitor: (monitor: Monitor) => void;
    handleLogOut: () => void;
    setIsLoading: (state: boolean) => void;
    tempMonitor: Monitor | null;
}

const Monitors: React.FC<MonitorProps> = ({ handleLogOut, handleLoading, developer, handleBlur }) => {
    const navigate = useNavigate();

    const { uuid } = useParams<{ uuid?: string }>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true); // New state to track the initial loading phase
    const [view, setView] = useState<'monitor_table' | 'monitor_edit'>('monitor_table');
    const [currentMonitor, setCurrentMonitor] = useState<Monitor | null>(null);
    const [tempMonitor, setTempMonitor] = useState<Monitor | null>(null);
    const [monitorsPreview, setMonitorsPreview] = useState<MonitorData[]>([]);
    const [scraped, setScraped] = useState(false);

    const getMonitors = useCallback(async () => {



        if ((initialLoading || monitorsPreview.length === 0) && !uuid) {
            try {
                const response = await axios.get(`${API_URL}/v1/monitors`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || ""
                    }
                });

                if (response.status === 200) {
                    const data = response.data.data.monitors;
                    setMonitorsPreview(data);
                }
            } catch (error) {
                handleLogOut();
            }
        }
    }, [handleLogOut]);

    const getCurrentMonitor = useCallback(async () => {
        try {
            console.log("a")
            setIsLoading(true);
            if (uuid) {
                const response = await axios.get(`${API_URL}/v1/monitors/${uuid}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || ""
                    }
                });
                if (response.status === 200) {
                    const currentMonitorScrape = response.data.monitor;
                    setCurrentMonitor(currentMonitorScrape);
                    setTempMonitor(currentMonitorScrape);
                    setView("monitor_edit");
                }
            }
        } catch (error) {
            console.log("monitor not found")
            navigate('/monitors');
            setScraped(false)

        } finally {
            setIsLoading(false);
            setInitialLoading(false); // End the initial loading phase
        }
    }, [uuid]);

    useEffect(() => {
        console.log("---")
        console.log(currentMonitor)
        console.log(uuid)
        console.log("---")
        if (uuid != null && (currentMonitor == null || !scraped)) {
            getCurrentMonitor();
            setScraped(true)
        } else {
            getMonitors();
            setInitialLoading(false);
        }
    }, [uuid, getMonitors, currentMonitor, getCurrentMonitor]);

    const handleEdit = (monitor: MonitorData) => {
        const monitorTemp: Monitor = {
            data: monitor,
            webhooks: [],
            inputs: []
        };
        setCurrentMonitor(monitorTemp);
        setTempMonitor(monitorTemp);
        setView("monitor_edit");
        navigate(`/monitors/${monitorTemp.data.monitor_uuid}`);
    };

    const handleBackToTable = () => {
        setView("monitor_table");
        setCurrentMonitor(null);
        setTempMonitor(null);
        console.log("back to table")
        navigate("/monitors");
        setScraped(false)
    };

    if (isLoading || initialLoading) {
        return (
            <div className="blur-container">
                <div className="loading-overlay">
                    <div className="loading-spinner"></div>
                </div>
            </div>
        );
    }

    return (
        <div className="scrollable">
            {developer && view === "monitor_table" && (
                <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="w-full md:w-1/2">
                        <ProxySettings />
                    </div>
                    <div className="w-full md:w-1/2">
                        <KeywordSettings />
                    </div>
                </div>

            )}
            {view === "monitor_edit" && currentMonitor && (
                <MonitorSettings
                    currentMonitor={currentMonitor}
                    handleBackToTable={handleBackToTable}
                    setCurrentMonitor={setCurrentMonitor}
                    setTempMonitor={setTempMonitor}
                    handleLogOut={handleLogOut}
                    setIsLoading={setIsLoading}
                    tempMonitor={tempMonitor}
                    handleBlur={handleBlur}
                    developer={developer}
                />
            )}
            {view === "monitor_table" && !isLoading && monitorsPreview && (
                <MonitorTable
                    monitorsPreview={monitorsPreview}
                    handleEdit={handleEdit}
                    developer={developer}
                />
            )}
        </div>
    );
};

export default Monitors;
