import React, { useState, useEffect } from "react";
import { Filter, ChevronDown, ChevronUp, Search, Edit2 } from 'lucide-react';

interface MonitorData {
    monitor_name: string;
    monitor_uuid: string;
    input_allowed: boolean;
    monitor_type: string;
    product: string;
    product_name: string;
    example_inputs: string[];
}

interface MonitorTableProps {
    monitorsPreview: MonitorData[];
    handleEdit: (monitor: MonitorData) => void;
    developer: Boolean;
}

const MonitorTable: React.FC<MonitorTableProps> = ({ monitorsPreview, handleEdit, developer }) => {
    const [selectedFilter, setSelectedFilter] = useState<string>("All");
    const [searchTerm, setSearchTerm] = useState('');
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [filteredMonitors, setFilteredMonitors] = useState<MonitorData[]>(monitorsPreview);
    const filters = ['All', 'Instore', 'Online'];

    useEffect(() => {
        let tempFiltered = monitorsPreview;

        if (selectedFilter !== "All") {
            tempFiltered = tempFiltered.filter(monitor => monitor.product_name === selectedFilter);
        }

        if (searchTerm !== "") {
            tempFiltered = tempFiltered.filter(monitor =>
                monitor.monitor_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        setFilteredMonitors(tempFiltered);
    }, [selectedFilter, monitorsPreview, searchTerm]);

    return (
        <div className="bg-[#2a2d3e] text-gray-100 p-8 sm:p-8 shadow-lg monitor-container">
            <h1 className="text-3xl font-bold mb-8">Monitor Overview</h1>


            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-6 space-y-4 sm:space-y-0 sm:space-x-4">
                {/* Filter */}
                <div className="relative w-full sm:w-auto">
                    <button
                        className="w-full sm:w-auto bg-[#212332] text-gray-300 rounded-lg px-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-between sm:justify-start space-x-2 transition-colors duration-200 hover:bg-[#2c2f3f]"
                        onClick={() => setFilterOpen(!filterOpen)}
                    >
                        <div className="flex items-center space-x-2">
                            <Filter size={14} />
                            <span>{selectedFilter}</span>
                        </div>
                        {filterOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                    </button>
                    {filterOpen && (
                        <div className="absolute mt-2 w-full sm:w-56 rounded-md shadow-lg bg-[#212332] ring-1 ring-black ring-opacity-5 z-10">
                            <div className="py-1" role="menu" aria-orientation="vertical">
                                {filters.map((filter) => (
                                    <span
                                        key={filter}
                                        className="block px-4 py-2 text-sm text-gray-300 hover:bg-[#2c2f3f] cursor-pointer transition-colors duration-200"
                                        role="menuitem"
                                        onClick={() => {
                                            setSelectedFilter(filter);
                                            setFilterOpen(false);
                                        }}
                                    >
                                        {filter}
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* Searchbar */}
                <div className="relative w-full sm:w-64">
                    <input
                        type="text"
                        placeholder="Search Monitor..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full bg-[#212332] text-gray-300 rounded-lg pl-10 pr-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                    />
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Search size={18} className="text-gray-500" />
                    </div>
                </div>
            </div>

            {/* Table */}
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead className="bg-[#212332] text-gray-400 text-xs sm:text-sm uppercase">
                        <tr>
                            {!developer && (
                                <th className="px-2 sm:px-6 py-3 text-left">Monitor</th>
                            )}
                            <th className="px-2 sm:px-6 py-3 text-left">Name</th>
                            <th className="px-2 sm:px-6 py-3 text-left">Product</th>
                            {developer && (
                                <th className="px-2 sm:px-6 py-3 text-left">Type</th>
                            )}
                            <th className="px-2 sm:px-6 py-3 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-700">
                        {filteredMonitors.map((monitor, i) => (
                            <tr key={monitor.monitor_uuid} className="hover:bg-[#2c2f3f] transition-colors duration-200">
                                {!developer && (
                                    <td className="px-2 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm">{i + 1}</td>
                                )}
                                <td className="px-2 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm">{monitor.monitor_name}</td>
                                <td className="px-2 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm">{monitor.product_name}</td>
                                {developer && (
                                    <td className="px-2 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm">{monitor.monitor_type}</td>
                                )}
                                <td className="px-2 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm">
                                    <button
                                        className="text-blue-400 hover:text-blue-300 transition-colors duration-200 flex items-center space-x-1"
                                        onClick={() => handleEdit(monitor)}
                                    >
                                        <Edit2 size={14} className="sm:hidden" />
                                        <Edit2 size={16} className="hidden sm:inline" />
                                        <span className="hidden sm:inline">Edit</span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MonitorTable;