import React, { useEffect } from 'react';
import { XCircle, Save } from 'lucide-react';
import { MonitorInput } from './types';
import ReactDOM from 'react-dom';



interface EditDialogProps {
    editingItem: MonitorInput;
    setEditingItem: (item: MonitorInput) => void;
    updateItem: (item: MonitorInput) => void;
    setIsEditDialogOpen: (isOpen: boolean) => void;
    isEditDialogOpen: boolean;
    availableRegions: string[];
    handleSizeRangeChange: (e: React.ChangeEvent<HTMLInputElement>, type: 'from' | 'to', item: MonitorInput) => void;
}

const EditDialog: React.FC<EditDialogProps> = ({
    editingItem,
    setEditingItem,
    updateItem,
    setIsEditDialogOpen,
    isEditDialogOpen,
    availableRegions,
    handleSizeRangeChange
}) => {
    useEffect(() => {
        const appDiv = document.querySelector('.App');
        if (isEditDialogOpen && appDiv) {
            appDiv.classList.add('blur_bg');
        } else if (appDiv) {
            appDiv.classList.remove('blur_bg');
        }

        // Cleanup function in case component unmounts
        return () => {
            if (appDiv) {
                appDiv.classList.remove('blur_bg');
            }
        };
    }, [isEditDialogOpen]);
    return ReactDOM.createPortal(
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-[#212332] rounded-lg p-6 w-full max-w-md">
                <h2 className="text-xl font-semibold mb-4">Edit Item</h2>
                <div className="space-y-4">
                    <div>
                        <label htmlFor="link" className="block text-sm font-medium mb-1">Link/Product ID</label>
                        <input
                            id="link"
                            value={editingItem.link}
                            onChange={(e) => setEditingItem({ ...editingItem, link: e.target.value })}
                            className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                        />
                    </div>
                    <div>
                        <label htmlFor="sizeRange" className="block text-sm font-medium mb-1">Size Range: {editingItem.sizeRange}</label>
                        <div className="flex items-center space-x-2">
                            <span className="text-xs">36</span>
                            <input
                                type="range"
                                min="36"
                                max="50"
                                value={editingItem.sizeRange.split('-')[0]}
                                onChange={(e) => handleSizeRangeChange(e, 'from', editingItem)}
                                className="flex-grow"
                            />
                            <input
                                type="range"
                                min="36"
                                max="50"
                                value={editingItem.sizeRange.split('-')[1]}
                                onChange={(e) => handleSizeRangeChange(e, 'to', editingItem)}
                                className="flex-grow"
                            />
                            <span className="text-xs">50</span>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="specialWebhook" className="block text-sm font-medium mb-1">Special Webhook</label>
                        <input
                            id="specialWebhook"
                            value={editingItem.specialWebhook}
                            onChange={(e) => setEditingItem({ ...editingItem, specialWebhook: e.target.value })}
                            className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                        />
                    </div>
                    <div>
                        <label htmlFor="note" className="block text-sm font-medium mb-1">Note</label>
                        <input
                            id="note"
                            value={editingItem.note}
                            onChange={(e) => setEditingItem({ ...editingItem, note: e.target.value })}
                            className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                        />
                    </div>
                    <div>
                        <label htmlFor="priceLimit" className="block text-sm font-medium mb-1">Price Limit</label>
                        <input
                            id="priceLimit"
                            type="number"
                            value={editingItem.priceLimit || ''}
                            onChange={(e) => setEditingItem({ ...editingItem, priceLimit: e.target.value ? Number(e.target.value) : undefined })}
                            className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                        />
                    </div>
                    {availableRegions.length > 1 && (
                        <div>
                            <label htmlFor="region" className="block text-sm font-medium mb-1">Region</label>
                            <div className="relative">
                                <select
                                    id="region"
                                    value={editingItem.region}
                                    onChange={(e) => setEditingItem({ ...editingItem, region: e.target.value })}
                                    className="w-full bg-gray-700 text-white px-3 py-2 rounded appearance-none pr-8"
                                >
                                    <option value="" disabled>Select Region</option>
                                    <option key="all" value="all">All</option>
                                    {availableRegions.map((region) => (
                                        <option key={region} value={region}>{region}</option>
                                    ))}
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mt-6 flex justify-center space-x-4">
                    <button
                        onClick={() => setIsEditDialogOpen(false)}
                        className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 flex items-center"
                    >
                        <XCircle size={18} className="mr-2" />
                        Cancel
                    </button>
                    <button
                        onClick={() => {
                            updateItem(editingItem);
                            setIsEditDialogOpen(false);
                        }}
                        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center"
                    >
                        <Save size={18} className="mr-2" />
                        Save
                    </button>
                </div>
            </div>
        </div>
        , document.body
    );


};

export default EditDialog;