import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonAddIcon from '@mui/icons-material/PersonAdd';


import Switch from '@mui/material/Switch';



import MenuIcon from '@mui/icons-material/Menu';
import CodeIcon from '@mui/icons-material/Code';
import BuildIcon from '@mui/icons-material/Build';
import ExtensionIcon from '@mui/icons-material/Extension';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import PaymentIcon from '@mui/icons-material/Payment';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import MonitorIcon from '@mui/icons-material/Monitor';
import PeopleIcon from '@mui/icons-material/People';




import CustomCommands from "../components/Sidebar/CustomCommands"
import BaseCommands from "../components/Sidebar/BaseCommands"
import SettingsTickets from "../components/Sidebar/SettingsTickets"
import LogsTickets from "../components/Sidebar/LogsTickets"

import DashboardAccess from "../components/Sidebar/DashboardAccess"
import NoPremium from "../components/Sidebar/NoPremium"

import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import HistoryIcon from '@mui/icons-material/History';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';





import Giveaways from "../components/Sidebar/Giveaways"
import Monitors from "../components/Sidebar/Monitor/MonitorMain"
import Settings from "../components/Sidebar/Settings"
import Updates from "../components/Sidebar/Updates"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Setup from "../components/Setup"



import Toolbar from '@mui/material/Toolbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/dash_styles.css";
import "../styles/loading_page.css";
import image from "../images/logo_new.png";
import PaymentView from '../components/Sidebar/Payment';
import { API_URL, DISCORDOAUTH } from '../constants';
import Customers from '../components/Sidebar/Customers';
import MonitorRedirect from '../components/Sidebar/Monitor/MonitorRedirect';


const drawerWidth = 240;



const darkModeTheme = createTheme({
    palette: {
        primary: {
            main: '#2a2d3e',
        },
        secondary: {
            main: '#ff8a65',
        },
        background: {
            default: '#212332',
            paper: '#fffff',
        },
        text: {
            primary: '#ffffff',
            secondary: '#c0c0c0',
        },
    },
});


interface DashboardProps {
    defaultItem: string
}
interface Purchase {
    monitor: string[];
    ticket: string[];
    toolbox: string[];
}

interface Customer {
    next_renewal: string
    image: string
    hex_color: string
    footer_text: string
    purchases: Purchase
    footer_icon: string;
    name: string;

}
interface Bot {
    id: number
    name: string
    app_id: string
    footer_text: string
    whitelisted_servers: Array<string>
    hex_color: String
}


const Dashboard: React.FC<DashboardProps> = ({ defaultItem }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [ticketsOpen, setTicketsOpen] = React.useState(false);
    const [monitorsOpen, setMonitorsOpen] = React.useState(false);


    const [selectedItem, setSelectedItem] = React.useState(defaultItem);

    const [authorized, setAuthorized] = React.useState(true);
    const [bearer, setBearer] = React.useState("");
    const [user_image, setImage] = React.useState("https://static.vecteezy.com/system/resources/previews/005/337/799/original/icon-image-not-found-free-vector.jpg");
    const [user_name, setName] = React.useState("N/A");
    const [hasBot, setHasBot] = React.useState(false);
    const [gotData, setGotData] = React.useState(false);
    const [purchases, set_purchases] = React.useState<Object>({});
    const [botData, setBotData] = React.useState<Bot | null>(null)
    const [customerData, setCustomerData] = React.useState<Customer | null>(null)
    const [blur, set_blur] = React.useState(false)
    const [no_premium, set_no_premium] = React.useState(false)
    const [developer, setDeveloper] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false);
    const [fetched, set_fetched] = React.useState(false);

    const navigate = useNavigate();
    const location = useLocation();



    const handleItemClick = (itemName: string) => {
        navigate(`/${itemName}`);  // Navigate to a URL including the itemName
        setSelectedItem(itemName);
        if (location.pathname.includes('/monitors/') && !location.pathname.includes('redirects')) {
            window.location.reload();
        }
        if (mobileOpen) {
            handleDrawerToggle()

        }
    };
    const handleOpenWebsite = () => {
        if (botData !== null) {
            window.open(`https://discord.com/api/oauth2/authorize?client_id=${botData["app_id"]}&permissions=8&scope=bot`, '_blank');
        }
    };
    const handleLogOut = async () => {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("image");
        const response = await axios.post(API_URL + '/v1/logout', null, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + bearer,
            },
        });

        setAuthorized(false);
        window.location.href = "http://dashboard.solvextools.com/";

    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const handleTicketsToggle = () => {
        setTicketsOpen(!ticketsOpen);
    };
    const handleMonitorToggle = () => {
        setMonitorsOpen(!monitorsOpen);
    };


    const drawer = (
        <div>
            <div className='sidebar_logo flex items-center justify-center'>
                <img src={image} alt='logo' className='sidebar_logo_image' />
            </div>
            <List>
                {/* Main "Commands" category */}
                <ListItem key="settings" disablePadding>
                    <ListItemButton
                        onClick={() => handleItemClick('settings')}
                        sx={{
                            backgroundColor: selectedItem === 'settings' ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            }
                        }}
                    >
                        <ListItemIcon><SettingsIcon style={{
                            color: '#7d7f8b'
                        }} /></ListItemIcon>
                        <ListItemText style={{ color: '#7d7f8b' }} primary="Settings" />
                    </ListItemButton>
                </ListItem>




                <ListItem key="commands" disablePadding>
                    <ListItemButton
                        onClick={() => handleItemClick('commands')}
                        sx={{
                            backgroundColor: selectedItem === 'commands' ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            }
                        }}
                    >
                        <ListItemIcon><CodeIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                        <ListItemText style={{ color: '#7d7f8b' }} primary="Commands" />
                    </ListItemButton>
                </ListItem>

                <ListItem key="updates" disablePadding>
                    <ListItemButton
                        onClick={() => handleItemClick('updates')}
                        sx={{
                            backgroundColor: selectedItem === 'updates' ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            }
                        }}
                    >
                        <ListItemIcon><AnnouncementIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                        <ListItemText style={{ color: '#7d7f8b' }} primary="Updates" />
                    </ListItemButton>
                </ListItem>

                <ListItem key="payment" disablePadding>
                    <ListItemButton
                        onClick={() => handleItemClick('payment')}
                        sx={{
                            backgroundColor: selectedItem === 'payment' ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            }
                        }}
                    >
                        <ListItemIcon><PaymentIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                        <ListItemText style={{ color: '#7d7f8b' }} primary="Payment" />
                    </ListItemButton>
                </ListItem>

                {"ticket" in purchases && (
                    <>
                        <ListItem key="ticket" disablePadding>
                            <ListItemButton onClick={handleTicketsToggle}>
                                <ListItemIcon><LiveHelpIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                                <ListItemText style={{ color: '#7d7f8b' }} primary="Tickets" />
                            </ListItemButton>
                        </ListItem>


                        <Collapse in={ticketsOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem key="settingsTickets" disablePadding>
                                    <ListItemButton
                                        onClick={() => handleItemClick('ticket/settings')}
                                        sx={{
                                            backgroundColor: selectedItem === 'ticket/settings' ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                            }
                                        }}
                                    >

                                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '24px' }}>
                                            <ListItemIcon><SettingsIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                                            <ListItemText style={{ color: '#7d7f8b' }} primary="Settings" />
                                        </Box>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem key="logsTickets" disablePadding>
                                    <ListItemButton
                                        onClick={() => handleItemClick('ticket/logs')}
                                        sx={{
                                            backgroundColor: selectedItem === 'ticket/logs' ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                            }
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '24px' }}>
                                            <ListItemIcon><HistoryIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                                            <ListItemText style={{ color: '#7d7f8b' }} primary="Logs" />
                                        </Box>
                                    </ListItemButton>
                                </ListItem>

                            </List>
                        </Collapse>
                    </>
                )}
                {"monitor" in purchases && (
                    <>
                        <ListItem key="monitor" disablePadding>
                            <ListItemButton onClick={handleMonitorToggle}>
                                <ListItemIcon><MonitorIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                                <ListItemText style={{ color: '#7d7f8b' }} primary="Monitors" />
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={monitorsOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem key="monitorOverview" disablePadding>
                                    <ListItemButton
                                        onClick={() => handleItemClick('monitors')}
                                        sx={{
                                            backgroundColor: selectedItem === 'monitors' ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                            }
                                        }}
                                    >

                                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '24px' }}>
                                            <ListItemIcon><SettingsIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                                            <ListItemText style={{ color: '#7d7f8b' }} primary="Overview" />
                                        </Box>
                                    </ListItemButton>
                                </ListItem>

                                <ListItem key="monitorRedirect" disablePadding>
                                    <ListItemButton
                                        onClick={() => handleItemClick('monitors/redirects')}
                                        sx={{
                                            backgroundColor: selectedItem === 'monitors/redirects' ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                            }
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '24px' }}>
                                            <ListItemIcon><ArrowForwardIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                                            <ListItemText style={{ color: '#7d7f8b' }} primary="Redirects" />
                                        </Box>
                                    </ListItemButton>
                                </ListItem>

                            </List>
                        </Collapse>
                    </>
                )}
                <ListItem key="dashboard-access" disablePadding>
                    <ListItemButton
                        onClick={() => handleItemClick('dashboard-access')}
                        sx={{
                            backgroundColor: selectedItem === 'dashboard-access' ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            }
                        }}
                    >
                        <ListItemIcon><PersonAddIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                        <ListItemText style={{ color: '#7d7f8b' }} primary="Dashboard Access" />
                    </ListItemButton>
                </ListItem>
                {developer && (
                    <ListItem key="customers" disablePadding>
                        <ListItemButton
                            onClick={() => handleItemClick('customers')}
                            sx={{
                                backgroundColor: selectedItem === 'customers' ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                }
                            }}
                        >
                            <ListItemIcon><PeopleIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                            <ListItemText style={{ color: '#7d7f8b' }} primary="Customers" />
                        </ListItemButton>
                    </ListItem>
                )}


            </List>
        </div>
    );
    const logout_darkmode = (
        <>

            <ListItem className="border border-gray-400 rounded" key="logout" disablePadding sx={{ position: 'absolute', bottom: "70px", width: "75%", marginLeft: "30px" }}>
                <ListItemButton onClick={() => handleOpenWebsite()}>
                    <ListItemIcon><AddCircleOutlineIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                    <ListItemText style={{ color: '#7d7f8b' }} primary="Invite Bot" />
                </ListItemButton>
            </ListItem>

            <ListItem className="border border-gray-400 rounded" key="logout" disablePadding sx={{ position: 'absolute', bottom: "10px", width: "75%", marginLeft: "30px" }}>
                <ListItemButton onClick={() => handleLogOut()}>
                    <ListItemIcon><ExitToAppIcon style={{ color: '#7d7f8b' }} /></ListItemIcon>
                    <ListItemText style={{ color: '#7d7f8b' }} primary="Log Out" />
                </ListItemButton>
            </ListItem>
        </>
    );

    const handleUpdateBot = async () => {
        get_bot_data()
    }

    const handleBotCreated = () => {
        setHasBot(true);
    };
    const get_bot_data = async () => {
        try {


            const response = await axios.get(`${API_URL}/v1/customer/info`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {

                if (response.data.data.paid) {
                    if ("app_id" in response.data.data.bot_data) {
                        var has_bot = response.data.data.has_bot;
                        var pending = response.data.data.bot_pending;
                        var purchases = response.data.data.customer_data.purchases

                        set_purchases(purchases)
                        if (has_bot && !pending) {
                            // full setup bot --> to dashboard
                            setHasBot(true);
                            setDeveloper(response.data.data.developer);
                            setBotData(response.data.data.bot_data);
                            setCustomerData(response.data.data.customer_data);
                        }
                        else {
                            setHasBot(false)
                        }
                    }

                }
                else {
                    //user has no premium
                    if (response.data.data.bot_data.length > 0) {// check if staff at other bot
                        setHasBot(true)
                        setBotData(response.data.data.bot_data)
                        purchases = response.data.data.customer_data.purchases
                        set_purchases(purchases)
                    }
                    set_no_premium(true)
                }
                setIsLoading(false)

            }
            set_fetched(true)
        } catch (error) {
            console.log(error)
            set_fetched(true)
            handleLogOut();
        }

    }

    const handleBlur = () => {
        console.log("set blur")
        set_blur(!blur)
    }
    const handleLoading = (bool) => {
        console.log("set loading")
        setIsLoading(bool)
    }

    React.useEffect(() => {

        try {
            const authToken = localStorage.getItem("token");
            const dc_name = localStorage.getItem("name");
            const dc_image = localStorage.getItem("image");
            setGotData(true)

            setBearer(String(authToken))
            setName(String(dc_name))
            setImage(String(dc_image))

            if (authToken === null || authToken === String(undefined)) {
                setAuthorized(false);
            }
            else {
                // check auth token
                setAuthorized(true);
            }

        }
        catch {
            // TODO redirect to error page
        }
    }, []);

    React.useEffect(() => {
        if (gotData) {
            get_bot_data()
        }
    }, [gotData]);

    if (isLoading) {
        return (<>
            <div className="loading-container">
                <div className="loading-spinner"></div>
            </div>
        </>
        )
    }
    else {


        if (!authorized) {
            window.location.replace(DISCORDOAUTH);
            return (
                <></>
            )

        }
        else {
            if (hasBot) {

                return (
                    <div className={`${blur ? 'blur_bg' : ''}`}>
                        <ThemeProvider theme={darkModeTheme}>
                            <CssBaseline />
                            <Box sx={{ display: 'flex' }}>
                                <AppBar
                                    position="fixed"
                                    sx={{
                                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                                        ml: { sm: `${drawerWidth}px` },
                                    }}
                                >
                                    <div className="dash_nav flex justify-between">
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            edge="start"
                                            onClick={handleDrawerToggle}
                                            sx={{ mr: 2, display: { sm: 'none' } }}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                        <div className="nav_text flex items-center h4">
                                            <div className="nav_headline">Dashboard</div>
                                        </div>
                                        <div className="flex items-center">
                                            <div className='profile flex items-center border border-white-400 rounded'>
                                                <div className='pr-2 nav_text'>{user_name}</div>
                                                <img src={user_image} alt='profile' className='profile_img rounded-full' />
                                            </div>
                                        </div>
                                    </div>
                                </AppBar>

                                <Box
                                    component="nav"
                                    sx={{
                                        width: { sm: drawerWidth },
                                        flexShrink: { sm: 0 },
                                        height: '100vh',
                                        overflow: 'auto',
                                        bgcolor: darkModeTheme.palette.primary.main,
                                    }}
                                >
                                    <Drawer
                                        variant="temporary"
                                        open={mobileOpen}
                                        onClose={handleDrawerToggle}
                                        ModalProps={{
                                            keepMounted: true,
                                        }}
                                        sx={{
                                            display: { xs: 'block', sm: 'none' },
                                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, bgcolor: darkModeTheme.palette.primary.main },
                                        }}
                                    >
                                        {drawer}
                                        {logout_darkmode}
                                    </Drawer>
                                    <Drawer
                                        variant="permanent"
                                        sx={{
                                            display: { xs: 'none', sm: 'block' },
                                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                        }}
                                        open
                                    >
                                        {drawer}
                                        {logout_darkmode}
                                    </Drawer>


                                </Box>

                                <Box
                                    component="main"
                                    sx={{
                                        flexGrow: 1,
                                        p: 3,
                                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                                        bgcolor: darkModeTheme.palette.background.default,
                                        color: darkModeTheme.palette.text.primary,
                                        '@media (max-width:600px)': {
                                            p: 0,
                                        },
                                    }}
                                >
                                    <Toolbar />

                                    <div>
                                        {selectedItem === "commands" && botData && <BaseCommands handleBlur={handleBlur} handleLogOut={handleLogOut} app_id={botData["app_id"]} />}
                                        {selectedItem === "customCommands" && <CustomCommands />}
                                        {selectedItem === "updates" && <Updates />}
                                        {selectedItem === "payment" && botData && customerData &&
                                            <PaymentView customerData={customerData} />
                                        }
                                        {selectedItem === "ticket/settings" && botData && customerData && <SettingsTickets handleLogOut={handleLogOut} name={botData["name"]} image={customerData["image"]} hex={customerData["hex_color"]} footer_icon={customerData["footer_icon"]} footer_text={botData["footer_text"]} app_id={botData["app_id"]} />}
                                        {selectedItem === "ticket/logs" && <LogsTickets handleLogOut={handleLogOut} />}
                                        {selectedItem === "giveaways" && <Giveaways />}
                                        {selectedItem === "monitors" && <Monitors handleLogOut={handleLogOut} handleLoading={handleLoading} developer={developer} handleBlur={handleBlur} />}
                                        {selectedItem === "monitors/redirects" && <MonitorRedirect />}
                                        {selectedItem === "dashboard-access" && botData && <DashboardAccess handleLogOut={handleLogOut} handleBlur={handleBlur} app_id={botData["app_id"]} />}
                                        {selectedItem === "customers" && developer && <Customers />}
                                        {selectedItem === "settings" && botData && customerData && (
                                            <Settings botData={botData} customerData={customerData} handleUpdateBot={handleUpdateBot} handleLogOut={handleLogOut} handleBlur={handleBlur} />
                                        )}
                                    </div>


                                </Box>
                            </Box>
                        </ThemeProvider>
                    </div>
                );
            }
            else if (no_premium && fetched) {
                return (
                    <NoPremium username={user_name} />
                )
            }
            else if (fetched) {
                return (
                    <Setup onBotCreated={handleBotCreated} handleLogOut={handleLogOut} />
                )
            }
            else {
                return (
                    <>
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                        </div>
                    </>
                )
            }
        }
    }


};

export default Dashboard;
