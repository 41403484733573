import React from 'react';
import { Monitor } from './types';

interface WebhookSectionProps {
    currentMonitor: Monitor;
    setCurrentMonitor: (monitor: Monitor) => void;
    setHasChanged: (changed: boolean) => void;
}

const WebhookSection: React.FC<WebhookSectionProps> = ({
    currentMonitor,
    setCurrentMonitor,
    setHasChanged
}) => {
    const handleWebhookChange = (event: React.ChangeEvent<HTMLInputElement>, region: string) => {
        const updatedWebhooks = currentMonitor.webhooks.map((webhook) =>
            webhook.region === region
                ? { ...webhook, webhook_url: event.target.value }
                : webhook
        );

        setCurrentMonitor({
            ...currentMonitor,
            webhooks: updatedWebhooks,
        });
        setHasChanged(true);
    };

    return (
        <div className="bg-gray-800 p-4 sm:p-6 rounded-lg mb-6 dark-container">
            <h3 className="text-lg font-semibold mb-3">Webhook Details</h3>
            {currentMonitor.webhooks.map((webhook) => (
                <div className="mb-4" key={webhook.region}>
                    <label className="block text-sm font-medium mb-1">{webhook.region}</label>
                    <input
                        type="text"
                        value={webhook.webhook_url}
                        placeholder="Input your discord webhook"
                        onChange={(e) => handleWebhookChange(e, webhook.region)}
                        className="w-full bg-gray-700 text-white px-3 py-2 rounded"
                    />
                </div>
            ))}
        </div>
    );
};

export default WebhookSection;