import React, { useState, useEffect, useRef } from "react";
import { toast, Id } from 'react-toastify';
import { ArrowLeft } from 'lucide-react';
import WebhookSection from './WebhookSection';
import MonitorDetailsSection from './MonitorDetailsSection';
import MonitoredLinksSection from './MonitoredLinksSection';
import { Monitor, MonitorSettingsProps, StatusCode } from './types';
import axios from "axios";
import { API_URL } from "../../../../constants";
import { CustomToastContainer } from "../../CustomCoastContainer";
import StatusCodeGraph from "./StatusCodeGraph";

const MonitorSettings: React.FC<MonitorSettingsProps> = ({
    currentMonitor,
    handleBackToTable,
    setCurrentMonitor,
    handleLogOut,
    setIsLoading,
    tempMonitor,
    setTempMonitor,
    handleBlur,
    developer
}) => {
    const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
    const [hasChanged, setHasChanged] = useState(false);
    const toastIdRef = useRef<Id | null>(null);
    const [showGraph, setShowGraph] = useState<boolean>(false);
    const [statusCodes, setStatusCodes] = useState<StatusCode[]>([]);

    const showToast = (message: string, type: "info" | "success" | "error") => {
        if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
            toast.update(toastIdRef.current, {
                render: message,
                type: type,
                isLoading: type === "info",
                autoClose: type !== "info" ? 3000 : false,
            });
        } else {
            toastIdRef.current = type === "info"
                ? toast.loading(message)
                : toast(message, {
                    type: type,
                    isLoading: false,
                    autoClose: 3000
                });
        }
    };

    useEffect(() => {
        if (hasChanged) {
            updateWebhooks();
        }
    }, [currentMonitor]);

    const updateWebhooks = async () => {
        showToast("Updating webhooks..", "info")

        if (currentMonitor) {
            try {
                const webhooksData = currentMonitor.webhooks.map((webhook) => ({
                    region: webhook.region,
                    webhook_url: webhook.webhook_url,
                }));

                const response = await axios.post(`${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/webhooks`, {
                    webhooks: webhooksData,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": localStorage.getItem("token") || "",
                    }
                });

                if (response.status === 200) {
                    console.log('Webhooks updated successfully');
                    showToast("Webhooks updated successfully", "success")
                    setBtnDisabled(true);
                }
            } catch (error) {
                console.error('Failed to update webhooks', error);
                showToast("Failed to update webhooks", "error")
                handleLogOut();
            } finally {
                setIsLoading(false);
            }
        }
    };
    const getStatusCodes = async () => {
        try {
            const response = await axios.get(`${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/graph`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token")
                }
            });
            if (response.status === 200) {
                setStatusCodes(response.data.data)
            }
        } catch (error) {
            handleLogOut();
        }
    }
    const handleGraphButtonClick = () => {


        if (!showGraph) {
            // get graph data
            getStatusCodes()
        }
        setShowGraph(!showGraph)

    }



    return (
        <div>
            <CustomToastContainer limit={1} />

            <div className="text-gray-100 p-2 sm:p-4 monitor-container">
                {developer && (
                    <button onClick={handleGraphButtonClick}>{showGraph ? "Hide" : "Show"} Graph</button>
                )}
                {showGraph && (
                    <div className="w-full h-[700px]">
                        <StatusCodeGraph data={statusCodes} />
                    </div>
                )}
                {!showGraph && (
                    <div className="max-w-4xl mx-auto">
                        <h2 className="text-xl font-semibold mb-4 mt-4 ml-1">Edit Monitor: {currentMonitor.data.monitor_name}</h2>

                        <button className="flex items-center text-blue-400 mb-4" onClick={handleBackToTable}>
                            <ArrowLeft size={20} className="mr-2" />
                            Back to Monitor List
                        </button>

                        <div className="mb-6">
                            <p><strong>Category:</strong> {currentMonitor.data.product_name}</p>
                        </div>

                        {developer && currentMonitor.monitorSettings && (
                            <MonitorDetailsSection
                                currentMonitor={currentMonitor}
                                setCurrentMonitor={setCurrentMonitor}
                                showToast={showToast}
                            />
                        )}

                        {currentMonitor.webhooks.length > 0 && (
                            <WebhookSection
                                currentMonitor={currentMonitor}
                                setCurrentMonitor={setCurrentMonitor}
                                setHasChanged={setHasChanged}
                            />
                        )}

                        <MonitoredLinksSection
                            currentMonitor={currentMonitor}
                            setCurrentMonitor={setCurrentMonitor}
                            tempMonitor={tempMonitor}
                            setTempMonitor={setTempMonitor}
                            showToast={showToast}
                            developer={developer}
                        />
                    </div>
                )}

            </div>
        </div>

    )
}

export default MonitorSettings;
