import React, { useState, useEffect, useRef } from 'react';
import { Monitor, MonitorInput } from './types';
import { Plus, X, SearchX, HelpCircle, ChevronUp, ChevronDown } from 'lucide-react';
import ExampleInputs from './ExampleInputs';
import InputList from './InputList';
import EditDialog from './EditDialog';
import InputsNotAllowedMessage from './InputsNotAllowedMessage';
import RegionSelect from './RegionSelect';
import OptionalInputs from './OptionalInputs';
import { API_URL } from '../../../../constants';
import axios, { AxiosError, AxiosResponse } from 'axios';

interface MonitoredLinksSectionProps {
    currentMonitor: Monitor;
    setCurrentMonitor: (monitor: Monitor) => void;
    tempMonitor: Monitor | null;
    setTempMonitor: (monitor: Monitor) => void;
    showToast: (message: string, type: "info" | "success" | "error") => void;
    developer: boolean;
}

const MonitoredLinksSection: React.FC<MonitoredLinksSectionProps> = ({
    currentMonitor,
    setCurrentMonitor,
    tempMonitor,
    setTempMonitor,
    showToast,
    developer
}) => {
    const [items, setItems] = useState<MonitorInput[]>(currentMonitor.inputs || []);
    const [newItem, setNewItem] = useState<MonitorInput>({
        link: '',
        sizeRange: '36-50',
        specialWebhook: '',
        region: "all",
        note: '',
        priceLimit: undefined,
        active: true,
        id: -1
    });
    const [showOptionalInputs, setShowOptionalInputs] = useState(false);
    const [showExamples, setShowExamples] = useState(false);
    const [editingItem, setEditingItem] = useState<MonitorInput | null>(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [changedItems, setChangedItems] = useState<MonitorInput[]>([]);
    const [filteredItems, setFilteredItems] = useState<MonitorInput[]>(currentMonitor.inputs || []);
    const [newItems, setNewItems] = useState<MonitorInput[]>([]);
    const [tooltip, setTooltip] = useState<{ content: string; x: number; y: number } | null>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const showConfirmationPopup = (): Promise<boolean> => {
        return new Promise((resolve) => {
            const confirmed = window.confirm("The input has already been added to the monitor. Are you sure you want to add it again?");
            resolve(confirmed);
        });
    };

    const sendRequest = async (items: any[], method: 'post' | 'put', url: string, force: boolean = false): Promise<AxiosResponse> => {
        try {
            const response = await axios({
                method: method,
                url: url,
                data: {
                    inputs: items,
                    force: force
                },
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": localStorage.getItem("token") || "",
                }
            });

            return response;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response && axiosError.response.status === 409) {
                    const confirmed = await showConfirmationPopup();
                    if (confirmed) {
                        return sendRequest(items, method, url, true);
                    } else {
                        throw new Error("User cancelled the operation");
                    }
                }
            }
            throw error;
        }
    };
    const updateItemsWithNewIds = (updatedItems: MonitorInput[]) => {
        setItems(prevItems => {
            const newItems = [...prevItems];
            updatedItems.forEach(updatedItem => {
                const existingItemIndex = newItems.findIndex(item => item.id === -1 && item.link === updatedItem.link);
                if (existingItemIndex !== -1) {
                    // Update the ID of the existing item
                    newItems[existingItemIndex] = { ...newItems[existingItemIndex], id: updatedItem.id };
                } else {
                    // Append the new item
                    newItems.push(updatedItem);
                }
            });
            return newItems;
        });
        setNewItems([]);
        setChangedItems([]);
    };

    const addItem = async () => {
        if (newItem.link) {
            const newItemWithId = { ...newItem, id: -1 };
            try {
                showToast("Adding new input...", "info");
                const response = await sendRequest([newItemWithId], 'post', `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/new`);
                const updatedNewItems = response.data.inputs;
                showToast("Input added successfully", "success");
                updateItemsWithNewIds(updatedNewItems);
                setNewItems(prevNewItems => [...prevNewItems, ...updatedNewItems]);
            } catch (error) {
                console.error('Failed to add new item', error);
                showToast("Failed to add input", "error");
            }
            setNewItem({
                link: '',
                sizeRange: '36-50',
                specialWebhook: '',
                region: 'all',
                note: '',
                priceLimit: undefined,
                active: true,
                id: -1
            });
            setShowOptionalInputs(false);
        }
    };

    const addItemDisabled = async () => {
        if (newItem.link) {
            const newItemWithId = { ...newItem, id: -1, active: false };
            try {
                showToast("Adding new disabled input...", "info");
                const response = await sendRequest([newItemWithId], 'post', `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/new`, true);
                const updatedNewItems = response.data.inputs;
                showToast("Input added successfully", "success");
                updateItemsWithNewIds(updatedNewItems);
                setNewItems(prevNewItems => [...prevNewItems, ...updatedNewItems]);
            } catch (error) {
                console.error('Failed to add new disabled item', error);
                showToast("Failed to add disabled input", "error");
            }
            setNewItem({
                link: '',
                sizeRange: '36-50',
                specialWebhook: '',
                region: 'all',
                note: '',
                priceLimit: undefined,
                active: true,
                id: -1
            });
            setShowOptionalInputs(false);
        }
    };

    const removeItem = async (index: number) => {
        const deletedItem = items[index];
        setItems(prevItems => prevItems.filter((_, i) => i !== index));
        setNewItems(prevNewItems => prevNewItems.filter(item => item.id !== deletedItem.id));
        setChangedItems(prevChangedItems => prevChangedItems.filter(item => item.id !== deletedItem.id));

        if (deletedItem.id !== -1) {
            try {
                showToast("Deleting input...", "info")
                const response = await axios.delete(`${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token') || '',
                    },
                    data: {
                        item: deletedItem
                    }
                });
                console.log('Item deleted:', response.data);
                showToast("Input deleted successfully", "success")
            } catch (error) {
                showToast("Error deleting input", "error")
            }
        }
    };

    const toggleActive = (index: number) => {
        setItems(prevItems => prevItems.map((item, i) => {
            if (i === index) {
                const updatedItem = { ...item, active: !item.active };
                if (item.id === -1) {
                    setNewItems(prevNewItems => prevNewItems.map(newItem =>
                        newItem.id === item.id ? updatedItem : newItem
                    ));
                } else {
                    setChangedItems(prevChangedItems => {
                        const existingItemIndex = prevChangedItems.findIndex(changedItem => changedItem.id === item.id);
                        if (existingItemIndex !== -1) {
                            return prevChangedItems.map(changedItem =>
                                changedItem.id === item.id ? updatedItem : changedItem
                            );
                        } else {
                            return [...prevChangedItems, updatedItem];
                        }
                    });
                }
                return updatedItem;
            }
            return item;
        }));
    };
    const handleTooltipLeave = () => {
        setTooltip(null);
    };

    const updateItem = (updatedItem: MonitorInput) => {
        setItems(prevItems => prevItems.map(item =>
            item.id === updatedItem.id ? { ...updatedItem } : item
        ));
        if (updatedItem.id === -1) {
            setNewItems(prevNewItems => prevNewItems.map(item =>
                item.id === updatedItem.id ? { ...updatedItem } : item
            ));
        } else {
            setChangedItems(prevChangedItems => {
                const existingItemIndex = prevChangedItems.findIndex(item => item.id === updatedItem.id);
                if (existingItemIndex !== -1) {
                    return prevChangedItems.map(item =>
                        item.id === updatedItem.id ? { ...updatedItem } : item
                    );
                } else {
                    return [...prevChangedItems, updatedItem];
                }
            });
        }
        setIsEditDialogOpen(false);
    };

    const updateMonitorInputs = async () => {
        if (currentMonitor && tempMonitor) {
            try {
                // Handle changed items
                if (changedItems.length > 0) {
                    showToast("Updating inputs...", "info");
                    setNewItems([]);
                    setChangedItems([]);
                    const response = await sendRequest(changedItems, 'put', `${API_URL}/v1/monitors/${currentMonitor.data.monitor_uuid}/inputs/update`);
                    console.log('Changed items updated successfully');
                    showToast("Inputs updated successfully", "success");
                }

                console.log('Monitor inputs updated successfully');


                setCurrentMonitor({
                    ...currentMonitor,
                    inputs: items
                });
                setTempMonitor({
                    ...currentMonitor,
                    inputs: items
                });
                setNewItems([]);
                setChangedItems([]);
            } catch (error) {
                console.error('Failed to update monitor inputs', error);
                showToast("Failed to update inputs", "error");
            }
        }
    };
    useEffect(() => {
        if (changedItems.length > 0) {
            console.log('Inputs have changed, updating monitor inputs...');
            updateMonitorInputs();
        }
    }, [changedItems]);
    const handleSizeRangeChange = (e: React.ChangeEvent<HTMLInputElement>, type: 'from' | 'to', item: MonitorInput | null = null) => {
        const [from, to] = (item ? item.sizeRange : newItem.sizeRange).split('-').map(Number);
        const value = parseInt(e.target.value);

        let newFrom = type === 'from' ? value : from;
        let newTo = type === 'to' ? value : to;

        if (newFrom > newTo) {
            if (type === 'from') {
                newTo = newFrom;
            } else {
                newFrom = newTo;
            }
        }


        const newSizeRange = `${newFrom}-${newTo}`;

        if (item) {
            setEditingItem({ ...item, sizeRange: newSizeRange });
        } else {
            setNewItem({ ...newItem, sizeRange: newSizeRange });
        }
    };
    useEffect(() => {
        if (searchTerm !== "") {
            const filtered = items.filter((product) =>
                product.link.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredItems(filtered);
        } else {
            setFilteredItems(items);
        }
    }, [items, searchTerm]);

    const handleTooltip = (e: React.MouseEvent<Element>, content: string) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const buttonCenter = rect.left + rect.width / 2;
        setTooltip({
            content,
            x: buttonCenter + window.scrollX,
            y: rect.top + window.scrollY - 5
        });
    };

    const openEditDialog = (item: MonitorInput) => {
        setEditingItem(item);
        setIsEditDialogOpen(true);
    };
    return (
        <div className="dark-container p-4 sm:p-6 rounded-lg mb-6">
            <h3 className="text-lg font-semibold mb-3">Monitored Links/Products</h3>
            {!currentMonitor.data.input_allowed && !developer ? (
                <InputsNotAllowedMessage />
            ) : (
                <div>
                    <div className="space-y-3 sm:space-y-4 mb-4">
                        <div className="flex items-center space-x-2">
                            <input
                                type="text"
                                placeholder="Enter link or product ID"
                                value={newItem.link}
                                onChange={(e) => setNewItem({ ...newItem, link: e.target.value })}
                                className="flex-grow bg-gray-700 text-gray-100 rounded pl-4 pr-4 py-2"
                            />
                            <button
                                onClick={addItem}
                                className="bg-green-500 hover:bg-green-600 text-white p-2.5 rounded-md transition-colors duration-300"
                                aria-label="Add Active Input"
                                onMouseLeave={handleTooltipLeave}
                                onMouseEnter={(e) => handleTooltip(e, "Add new input")}
                            >
                                <Plus size={16} />
                            </button>
                            <button
                                onClick={addItemDisabled}
                                className="bg-red-500 hover:bg-red-600 text-white p-2.5 rounded-md transition-colors duration-300"
                                aria-label="Add Disabled Input"
                                onMouseLeave={handleTooltipLeave}
                                onMouseEnter={(e) => handleTooltip(e, "Add disabled input")}
                            >
                                <X size={16} />
                            </button>
                            <button
                                onClick={() => setShowExamples(!showExamples)}
                                className="bg-blue-500 hover:bg-blue-600 text-white p-2.5 rounded-md transition-colors duration-300"
                                aria-label="Toggle Example Inputs"
                                onMouseEnter={(e) => handleTooltip(e, `${showExamples ? "Hide" : "Show"} Example Inputs`)}
                                onMouseLeave={handleTooltipLeave}
                            >
                                <HelpCircle size={16} />
                            </button>
                        </div>

                        {currentMonitor.webhooks.length > 1 && (
                            <RegionSelect
                                newItem={newItem}
                                setNewItem={setNewItem}
                                availableRegions={currentMonitor.webhooks.map(webhook => webhook.region)}
                            />
                        )}

                        {showExamples && (
                            <ExampleInputs inputs={currentMonitor.data.example_inputs} />
                        )}

                        <OptionalInputs
                            showOptionalInputs={showOptionalInputs}
                            setShowOptionalInputs={setShowOptionalInputs}
                            newItem={newItem}
                            setNewItem={setNewItem}
                            handleSizeRangeChange={handleSizeRangeChange}
                        />
                    </div>

                    <InputList
                        items={filteredItems}
                        availableRegions={currentMonitor.webhooks.map(webhook => webhook.region)}
                        toggleActive={toggleActive}
                        openEditDialog={openEditDialog}
                        removeItem={removeItem}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                    />
                </div>
            )}

            {isEditDialogOpen && editingItem && (
                <EditDialog
                    editingItem={editingItem}
                    setEditingItem={setEditingItem}
                    updateItem={updateItem}
                    setIsEditDialogOpen={setIsEditDialogOpen}
                    isEditDialogOpen={isEditDialogOpen} // Add this line
                    availableRegions={currentMonitor.webhooks.map(webhook => webhook.region)}
                    handleSizeRangeChange={handleSizeRangeChange}
                />
            )}
            {tooltip && (
                <div
                    ref={tooltipRef}
                    className="fixed bg-gray-800 text-white px-3 py-2 rounded-lg shadow-lg z-50 text-sm transition-opacity duration-150 ease-in-out"
                    style={{
                        left: `${tooltip.x}px`,
                        top: `${tooltip.y}px`,
                        transform: 'translate(-50%, -100%)',
                        opacity: tooltip ? 1 : 0,
                        pointerEvents: 'none',
                        backgroundColor: "#2a2d3e"
                    }}
                >
                    {tooltip.content}
                    <div
                        className="absolute left-1/2 bottom-0 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-[#2a2d3e]"
                    ></div>
                </div>
            )}
        </div>
    );
};

export default MonitoredLinksSection;
